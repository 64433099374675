.before-after-slider {
  width: 100%;
  position: absolute;
  height: 70%;
  z-index: 4;
}

.before-after-slider__first-photo-container, .before-after-slider__second-photo-container {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.before-after-slider__second-photo-container {
  top: 0;
  position: absolute;
}

.before-after-slider img {
  max-width: none;
  width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: cover;
  height: 100%;
}

.before-after-slider__delimiter {
  position: absolute;
  width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(70vh - 168px);
  top: 85px;
}

@media (max-width: 768px) {
  .before-after-slider__delimiter {
    position: absolute;
    width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(60vh - 100px);
    top: 87px;
  }
}

.before-after-slider__delimiter-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.before-after-slider__delimiter-img {
  cursor: pointer;
  user-select: none;
}

