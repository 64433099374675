.carousel .control-dots .dot {
    background-color: black !important;
    opacity: 0.3 !important;
    box-shadow: initial !important;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    opacity: 1 !important;
}

.carousel .control-dots {
    bottom: -32px !important;
}

.carousel.carousel-slider {
    overflow: visible !important;
}

.carousel {
    aspect-ratio: 4/5;
}

.carousel .slide img {
    height: 100%;
}