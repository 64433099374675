.container {
	position: relative;
	box-sizing: border-box;
	border: solid rgb(230, 230, 230) 1px;
	font: normal normal 700 16px/52px 'Clinique Helvetica Neue';
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Dropdown-option {
	padding-top: 12px;
	padding-left: 16px;
	padding-right: 32px;
	line-height: 22px;
	display: flex;
}

.is-open {
	line-height: 22px;
	padding-top: 15px;
	padding-bottom: 16px;
}

.arrow {
	width: 10px;
	background-image: url('../assets/icons/DropDownArrow.svg');
	background-repeat: no-repeat;
	right: 12px;
	top: 24px;
	height: 5px;
	position: absolute;
	transition: all 0.3s;
}

.arrow-open {
	width: 10px;
	background-image: url('../assets/icons/DropDownArrow.svg');
	background-repeat: no-repeat;
	transform: rotate(90deg);
	right: 12px;
	top: 24px;
	height: 5px;
	position: absolute;
	transition: all 0.3s;
}

.shade {
	height: 8px;
	width: 54px;
	padding: 0px;
	color: transparent;
	background-color: aqua;
}

.Dropdown-group {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.Dropdown-control {
	padding: 0px 32px 0px 16px;
}
